import "core-js/modules/es.array.push.js";
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-760d0087"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "first-slot"
};
const _hoisted_2 = {
  key: 1,
  class: "title"
};
const _hoisted_3 = {
  class: "sku-tabs"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$spuDetail, _ctx$spuDetail2;
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['solution-detail-header-info', _ctx.isRowDirection ? 'row-info' : 'column-info'])
  }, [_ctx.isRowDirection ? (_openBlock(), _createElementBlock("section", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : _createCommentVNode("", true), !_ctx.isRowDirection ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString((_ctx$spuDetail = _ctx.spuDetail) === null || _ctx$spuDetail === void 0 ? void 0 : _ctx$spuDetail.spu_name), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx$spuDetail2 = _ctx.spuDetail) === null || _ctx$spuDetail2 === void 0 ? void 0 : _ctx$spuDetail2.skus, item => {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      class: _normalizeClass(['sku-tabs__item', {
        'is-active': item.id === +_ctx.skuId
      }]),
      onClick: $event => _ctx.router.push({
        name: 'SolutionDetail',
        params: {
          spuId: _ctx.spuId,
          skuId: item.id
        }
      })
    }, _toDisplayString(item.sku_name), 11, _hoisted_4);
  }), 128))]), _createVNode(_component_el_button, {
    type: "primary",
    class: "button-buy",
    onClick: _ctx.handleBtnClick
  }, {
    default: _withCtx(() => {
      var _ctx$currentSku;
      return [_createTextVNode(_toDisplayString(((_ctx$currentSku = _ctx.currentSku) === null || _ctx$currentSku === void 0 ? void 0 : _ctx$currentSku.buy_type) === _ctx.BUY_TYPE.CONSULTING ? '立即定制' : '立即购买'), 1)];
    }),
    _: 1
  }, 8, ["onClick"])], 2);
}