import { reactive, defineComponent, computed, ref, unref, toRaw, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import TForm from '@/components/TForm/index.vue';
import { getHTTPUrlRule, getMaxStringRule, getPhoneRule } from '@/base/utils/formRules';
import { fetchSubmitJoinus } from '@/base/api/common';
import useCountryOptions from '@/base/hooks/useCountryOptions';
/**
 * 扬帆定制版弹窗
 * @author dingxin02
 * @date 2022-2-7
 */
export default defineComponent({
  name: 'CustomizedFormDialog',
  components: {
    TForm
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: [String, Number],
      default: '50%'
    },
    submitFormData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['set-dialog', 'form-submit', 'submit-success'],
  setup(props, {
    emit
  }) {
    const isDialogVisibled = computed({
      get: () => {
        return Boolean(props.dialogVisible);
      },
      set: val => {
        emit('set-dialog', val);
      }
    });
    const formRef = ref(null);
    const closeDialog = () => {
      var _formRef$value;
      isDialogVisibled.value = false;
      // 清空表单
      (_formRef$value = formRef.value) === null || _formRef$value === void 0 || _formRef$value.resetFields();
    };
    const formData = reactive({
      company: '',
      name: '',
      phone: '',
      captcha: '',
      domestic_site: '',
      foreign_site: '',
      country_code: ''
    });
    const {
      countryOptions
    } = useCountryOptions({
      globalItem: false
    });
    const formConfig = computed(() => [{
      label: '公司名称',
      prop: 'company',
      type: 'input',
      required: true,
      rules: [getMaxStringRule(100)]
    }, {
      label: '联系人',
      prop: 'name',
      type: 'input',
      required: true,
      rules: [getMaxStringRule(100)]
    }, {
      label: '联系人手机号',
      prop: 'phone',
      type: 'input',
      required: true,
      params: {
        type: 20
      },
      rules: [getPhoneRule()]
    }, {
      label: '验证码',
      prop: 'captcha',
      type: 'input',
      required: true
    }, {
      label: '公司国内官网',
      prop: 'domestic_site',
      type: 'input',
      required: true,
      rules: [getMaxStringRule(100), getHTTPUrlRule()]
    }, {
      label: '公司海外官网',
      prop: 'foreign_site',
      type: 'input',
      required: true,
      rules: [getMaxStringRule(100), getHTTPUrlRule()]
    }, {
      label: '目标投放国家',
      prop: 'country_code',
      type: 'cascader',
      required: true,
      cascaderOptions: toRaw(unref(countryOptions))
    }]);
    const handleSubmit = () => {
      var _formRef$value2;
      (_formRef$value2 = formRef.value) === null || _formRef$value2 === void 0 || _formRef$value2.validate(async isValid => {
        if (!isValid) {
          // 验证失败
          return false;
        }
        // 发送入驻请求
        postSubmitJoinus();
        async function postSubmitJoinus() {
          const params = {
            ...props.submitFormData,
            ...formData,
            country_code: +formData.country_code
          };
          try {
            const res = await fetchSubmitJoinus(params, {
              silent: true
            });
            const {
              errno,
              errmsg
            } = res;
            if (errno === 0) {
              emit('submit-success', true);
            } else {
              ElMessage.error(errmsg);
            }
          } catch (error) {
            ElMessage.error('留资信息提交失败，请稍后再试');
          } finally {
            nextTick(() => {
              closeDialog();
            });
          }
        }
      });
    };
    return {
      formData,
      formConfig,
      handleSubmit,
      formRef,
      isDialogVisibled,
      // function
      closeDialog
    };
  }
});