/**
 * @file 地区列表数据
 */

import {ref} from 'vue';
import {getCountryList} from '@/base/api/common';
import {IOptionItem} from '@/base/api/interface/common';

function useCountryOptions(option?: {
    globalItem?: boolean; // 是否包含『全球』选项
}) {
    const countryOptions = ref<IOptionItem[]>([]);
    const fetchCountryData = async () => {
        try {
            const res = await getCountryList();
            const list = res.data || [];
            countryOptions.value = list.map(country => ({
                label: country.country,
                value: country.country_code,
            }));
            if (option?.globalItem) {
                countryOptions.value.unshift({
                    label: '全球',
                    value: 'global',
                });
            }
        }
        catch (error) {
            // TODO 接口失败
        }
    };

    fetchCountryData();

    return {
        countryOptions,
        fetchCountryData,
    };
}

export default useCountryOptions;
