import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import JoinusForm from '@/views/components/Joinus/JoinusForm.vue';
import RichText from '@/components/RichText/index.vue';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { fetchOrderDetailHtml } from '@/base/api/order';
import { OrderMutationEnum } from '@/store/order';
export default defineComponent({
  name: 'SolutionDetailContent',
  components: {
    RichText,
    JoinusForm
  },
  setup() {
    const route = useRoute();
    const spuId = computed(() => route.params.spuId);
    const {
      state
    } = useStoreRequest({
      space: 'order',
      mutationName: OrderMutationEnum.SET_MERGE_ORDER_HTML_DATA,
      stateKey: 'mergeDetailHtmlData'
    }, fetchOrderDetailHtml, {
      spu: spuId.value
    });
    const detailInfo = computed(() => {
      var _state$value$data;
      return (_state$value$data = state.value.data) === null || _state$value$data === void 0 ? void 0 : _state$value$data.detailInfo;
    });
    return {
      detailInfo
    };
  }
});