import {API} from '@/base/http';
import {ISpuDetailRes, IDetailHtmlRes, ISpuDetailReq} from '@/base/api/interface/order';
import {IS_NODE} from '@/base/utils';

export const PATHS = {
    detail: '/product/spu/detail',
};

/**
 * 获取spu详情出参
 * API文档：https://yapi.baidu-int.com/project/25966/interface/api/1439331
 */
export const fetchSpuDetail = (params: ISpuDetailReq & {isLogin?: boolean, ck?: string, host?: string}) => {
    const {isLogin = false, ck, host, ...rest} = params;

    // 是否登录、调用不同 prefix
    let prefix = isLogin ? `${process.env.VUE_APP_PROXY_AUTH_API}/waimao`
        : `${process.env.VUE_APP_WAIMAO_GUEST_API}`;

    const cfg = IS_NODE && isLogin ? {
        cookie: ck,
        host: host,
    } : {};

    return API.get<ISpuDetailRes>(PATHS.detail, {
        baseURL: `${prefix}${process.env.VUE_APP_MALL_API}`,
        data: {
            ...rest,
        },
        headers: {
            ...cfg,
        },
    });
};

/**
 * 获取spu详情出参
 * API文档：TODO
 */
export const fetchOrderDetailHtml = ({spu}: {spu: ISpuDetailReq['id']} = {spu: 3000}) => {
    // productDetailInfo.json文件有缓存问题，暂时先加参数临时解决
    return API.get<IDetailHtmlRes>(`/dev/spu/${spu}/productDetailInfo.json`, {baseURL: '/b2b-waimao-finance'});
};
