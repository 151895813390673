import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c03b4e52"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_solution_header = _resolveComponent("solution-header");
  const _component_solution_content = _resolveComponent("solution-content");
  const _component_submit_success_dialog = _resolveComponent("submit-success-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_solution_header, {
    data: _ctx.data,
    onSubmitSuccess: _ctx.setSubmitSuccessDialog
  }, null, 8, ["data", "onSubmitSuccess"]), _createVNode(_component_solution_content), _createVNode(_component_submit_success_dialog, {
    "dialog-visible": _ctx.dialogVisible,
    onSetDialog: _ctx.setSubmitSuccessDialog
  }, null, 8, ["dialog-visible", "onSetDialog"])]);
}