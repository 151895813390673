import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
/**
 * 表单提交成功弹窗
 * @author dingxin02
 * @date 2022-2-25
 */
export default defineComponent({
  name: 'SubmitSuccessDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['set-dialog'],
  setup(props, {
    emit
  }) {
    const router = useRouter();
    const isDialogVisibled = computed({
      get: () => {
        return Boolean(props.dialogVisible);
      },
      set: val => {
        emit('set-dialog', val);
      }
    });
    const closeDialog = () => {
      isDialogVisibled.value = false;
    };
    return {
      isDialogVisibled,
      router,
      // function
      closeDialog
    };
  }
});