import "core-js/modules/es.array.push.js";
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HeaderInfo from './HeaderInfo.vue';
import CustomizedFormDialog from './CustomizedFormDialog.vue';
import { useScroll } from '@/base/hooks/useScroll';
import { IS_NODE } from '@/base/utils';
import { timeStampFormat } from '@/base/utils/time';
/**
 * @const {Number} 滚动阈值
 */
const THRESHOLD = 350;
export default defineComponent({
  name: 'SolutionDetailHeader',
  components: {
    HeaderInfo,
    CustomizedFormDialog
  },
  props: {
    data: {
      type: Object || undefined,
      default: undefined
    }
  },
  emits: ['submit-success'],
  setup(props, {
    emit
  }) {
    const route = useRoute();
    const router = useRouter();
    const dialogVisible = ref(false);
    let setDialog = val => {
      dialogVisible.value = !!val;
    };
    let submitSuccess = val => {
      emit('submit-success', val);
    };
    const scroll = IS_NODE ? undefined : useScroll(ref(window));
    const drawerShowFilter = computed(() => scroll && scroll.y.value > THRESHOLD);
    const drawerVisible = computed(() => Boolean(drawerShowFilter.value));
    const spuId = computed(() => route.params.spuId);
    // onMounted(() => {
    //     if (!data.value) {
    //         // getData({
    //         //     id: Number(spuId.value as string),
    //         //     isLogin: userState?.value.isLogin,
    //         // });
    //     }
    // });
    const skuId = computed(() => {
      var _props$data;
      return route.params.skuId || ((_props$data = props.data) === null || _props$data === void 0 || (_props$data = _props$data.skus) === null || _props$data === void 0 || (_props$data = _props$data[0]) === null || _props$data === void 0 ? void 0 : _props$data.id) || '';
    });
    const currentSku = computed(() => {
      var _props$data2, _props$data3, _props$data5;
      const sku = (_props$data2 = props.data) === null || _props$data2 === void 0 || (_props$data2 = _props$data2.skus) === null || _props$data2 === void 0 ? void 0 : _props$data2.filter(item => item.id === +(skuId === null || skuId === void 0 ? void 0 : skuId.value))[0];
      if (!sku && (_props$data3 = props.data) !== null && _props$data3 !== void 0 && (_props$data3 = _props$data3.skus) !== null && _props$data3 !== void 0 && _props$data3[0].id) {
        var _props$data4;
        router.push({
          name: 'SolutionDetail',
          params: {
            spuId: spuId.value,
            skuId: (_props$data4 = props.data) === null || _props$data4 === void 0 || (_props$data4 = _props$data4.skus) === null || _props$data4 === void 0 ? void 0 : _props$data4[0].id
          }
        });
      }
      return sku || ((_props$data5 = props.data) === null || _props$data5 === void 0 || (_props$data5 = _props$data5.skus) === null || _props$data5 === void 0 ? void 0 : _props$data5[0]);
    });
    const submitFormData = computed(() => {
      var _currentSku$value, _props$data6;
      return {
        sku_id: +skuId.value,
        // 20-商城留资
        type: 20,
        sku_name: (_currentSku$value = currentSku.value) === null || _currentSku$value === void 0 ? void 0 : _currentSku$value.sku_name,
        spu_name: (_props$data6 = props.data) === null || _props$data6 === void 0 ? void 0 : _props$data6.spu_name
      };
    });
    return {
      drawerVisible,
      currentSku,
      dialogVisible,
      submitFormData,
      router,
      // function
      setDialog,
      timeStampFormat,
      submitSuccess
    };
  }
});