import { defineComponent, ref, computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SolutionHeader from './components/Header.vue';
import SolutionContent from './components/Content.vue';
import SubmitSuccessDialog from './components/SubmitSuccessDialog.vue';
// import NotFound from '@/components/NotFound/index.vue';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { fetchSpuDetail } from '@/base/api/order';
import { OrderMutationEnum } from '@/store/order';
import { useStore } from '@/store';
import { USE_LOGIN_INJECT_KEY } from '@/base/hooks/useLogin';
export default defineComponent({
  name: 'SolutionDetail',
  components: {
    SolutionHeader,
    SolutionContent,
    SubmitSuccessDialog
    // NotFound,
  },
  setup() {
    var _store$state$headers, _store$state$headers2;
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const dialogVisible = ref(false);
    let setSubmitSuccessDialog = val => {
      dialogVisible.value = val;
    };
    const userState = inject(USE_LOGIN_INJECT_KEY);
    const spuId = computed(() => route.params.spuId);
    const {
      state
    } = useStoreRequest({
      space: 'order',
      mutationName: OrderMutationEnum.SET_MERGE_ORDER_DATA,
      stateKey: 'mergeDetailData',
      handlePayload: data => {
        return {
          [spuId.value]: data
        };
      },
      disabledServerPrefetch: true
    }, fetchSpuDetail, {
      id: spuId.value,
      isLogin: userState === null || userState === void 0 ? void 0 : userState.value.isLogin,
      ck: (_store$state$headers = store.state.headers) === null || _store$state$headers === void 0 ? void 0 : _store$state$headers.ck,
      host: (_store$state$headers2 = store.state.headers) === null || _store$state$headers2 === void 0 ? void 0 : _store$state$headers2.host
    });
    // const data = computed(() => state.value?.data?.[spuId.value as string]
    //     || {isError: !state.value?.isLoading});
    const data = computed(() => {
      var _state$value;
      return (_state$value = state.value) === null || _state$value === void 0 || (_state$value = _state$value.data) === null || _state$value === void 0 ? void 0 : _state$value[spuId.value];
    });
    return {
      data,
      dialogVisible,
      setSubmitSuccessDialog,
      router
    };
  }
});