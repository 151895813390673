import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c230b60"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "solution-detail-content-wrap"
};
const _hoisted_2 = {
  class: "solution-detail-content"
};
const _hoisted_3 = {
  class: "titie"
};
const _hoisted_4 = {
  class: "solution-detail-form"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_rich_text = _resolveComponent("rich-text");
  const _component_joinus_form = _resolveComponent("joinus-form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailInfo, (item, index) => {
    return _openBlock(), _createElementBlock("section", {
      key: index,
      class: "content-container"
    }, [_createElementVNode("h2", _hoisted_3, _toDisplayString(item.title), 1), _createVNode(_component_rich_text, {
      "final-text": item.content
    }, null, 8, ["final-text"])]);
  }), 128))]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_joinus_form)])]);
}