/**
 * @file 富文本内容
 * @author dingxin02@baidu.com
 */
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'RichText',
  props: {
    finalText: {
      type: String,
      default: ''
    }
  }
});