import { defineComponent, computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { showUcCommonLoginDialog } from '@baidu/trade-util/utils/login';
// import Price from './Price.vue';
import { ElMessageBox } from 'element-plus';
import { USE_LOGIN_INJECT_KEY } from '@/base/hooks/useLogin';
const BUY_TYPE = {
  BASE: 10,
  MARKETING: 20,
  CONSULTING: 30 // 30-留资
};
// 售卖渠道
const SELLING_CHANNEL = {
  TOURISTS: '10',
  TP: '20',
  AGENT: '30' // 30-大搜代理商
};
const EFFECTIVE_PERIOD_UNIT = ['', '日', '月', '年'];
export default defineComponent({
  name: 'HeaderInfo',
  components: {
    // Price,
  },
  props: {
    // 是否是横向样式
    isRowDirection: {
      type: Boolean,
      default: false
    },
    spuDetail: {
      type: Object || undefined,
      default: undefined
    },
    currentSku: {
      type: Object || undefined,
      default: undefined
    }
  },
  emits: ['set-dialog'],
  setup(props, {
    emit
  }) {
    const router = useRouter();
    const route = useRoute();
    // const {userState} = useLogin();
    const userState = inject(USE_LOGIN_INJECT_KEY);
    const spuId = computed(() => route.params.spuId);
    const skuId = computed(() => {
      var _props$spuDetail;
      return route.params.skuId || ((_props$spuDetail = props.spuDetail) === null || _props$spuDetail === void 0 ? void 0 : _props$spuDetail.skus[0].id) || '';
    });
    let handleBtnClick = () => {
      var _props$currentSku;
      if (((_props$currentSku = props.currentSku) === null || _props$currentSku === void 0 ? void 0 : _props$currentSku.buy_type) === BUY_TYPE.CONSULTING) {
        emit('set-dialog', true);
      } else {
        if (userState !== null && userState !== void 0 && userState.value.isLogin) {
          var _props$spuDetail2;
          if ((userState === null || userState === void 0 ? void 0 : userState.value.loginType) === 'passport') {
            // todo: 提示文字修改
            return ElMessageBox.alert('请联系您的服务商进行购买', '提示', {
              confirmButtonText: '关闭',
              type: 'warning'
            });
          }
          window.open(`/mall/order/buy?spu=${spuId.value}&sku=${skuId.value}`, `${((_props$spuDetail2 = props.spuDetail) === null || _props$spuDetail2 === void 0 ? void 0 : _props$spuDetail2.spu_name) || ''}`);
        } else {
          showUcCommonLoginDialog();
        }
      }
    };
    return {
      skuId,
      spuId,
      router,
      EFFECTIVE_PERIOD_UNIT,
      BUY_TYPE,
      handleBtnClick
    };
  }
});